.outcome-table-wrapper{
  border: 1px solid #EBEEF1;
  border-radius: 5px;
  overflow: hidden;

  td:nth-child(1), th:nth-child(1) {
    max-width: 40px;
    min-width: 40px;
  }

  td:nth-child(2), th:nth-child(2), td:nth-child(3), th:nth-child(3), td:nth-child(4), th:nth-child(4), td:nth-child(7), th:nth-child(7), td:nth-child(8), th:nth-child(8) {
    max-width: 100px;
    min-width: 100px;
  }

  td:nth-child(5), th:nth-child(5), td:nth-child(6), th:nth-child(6) {
    flex: 1;
    min-width: 100px;
  }
}