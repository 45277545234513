@import "./colors";

@mixin lng-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  color: $primary-color;
  cursor: pointer;
  background-color: #EBEEF1;
  border: none;
  font-weight: 700;
  font-size: 16px;
  outline: none;
  text-align: center;

  &.active {
    color: #FFFFFF;
    background-color: $secondary-color;
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
}

@mixin input {
  background: #F8F9FA;
  border: 1px solid #EBEEF1;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 40px;
  width: 100%;
  font-size: 16px;
  outline: none;
  padding: 0 16px;
}

@mixin nav-bar-icon {
  left: 220px;
  color: $primary-color;
  cursor: pointer;
  margin-left: 55px;

  @media (min-width: 600px) {
    display: none;
  }
}

@mixin lng-change-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .language {
    margin-right: 15px;
    color: #939393;
  }

  .lng-button {
    @include lng-button;
  }
}

@mixin page-title {
  font-weight: 900;
  font-size: 48px;
  color: #003476;
  width: 95%;
  line-height: 48px;
  margin: 0;
  display: flex;

  @media (max-width: 600px) {
    font-size: 36px;
  }
}

@mixin profile-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  outline: none;
  background: none;
  border: none;
  min-height: 50px;
  background: #ffffff;
  box-shadow: 0px 10px 30px #e7f0f7;
  border-radius: 35px;
  font-size: 15px;
  font-weight: 700;
  color: $primary-color;
  margin: auto 0 50px 0;
  cursor: pointer;
  text-decoration: none;
  word-break: break-word;
  width: 100%;
  max-width: 200px;

  .user-avatar {
    height: 25px;
    width: 25px;
  }

  span {
    margin-right: 10px;
    font-weight: 900;
    text-align: center;
    max-width: 70%;
  }
}

@mixin app-logo {
  cursor: pointer;
  margin-top: 45px;

  @media (max-width: 600px) {
    margin-top: 65px;
  }
}

@mixin error {
  margin-top: 10px;
  color: $error-color;
  font-size: 12px;
}

@mixin reset-password {
  display: flex;
  justify-content: flex-start;
  color: #494949;
  width: 80%;
  font-size: 18px;
}

@mixin month-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  font-size: 18px;
  color: #939393;
  background-color: #efefef;
  margin-left: 5px;
}
