@import '../../scss/mixins.scss';

.user-page-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 5%;
  flex-wrap: wrap;

  input[type='text'],
  input[type='password'] {
    @include input;
    width: 95%;
    font-weight: 700;
  }

  .user-page-title {
    font-weight: 900;
    font-size: 48px;
    color: $primary-color;
    margin: 6px 0 50px 0;
    display: flex;
    justify-content: flex-start;

    @media (max-width: 600px) {
      font-size: 36px;
    }
  }

  .user-content-row {
    width: 100%;

    @media (max-width: 600px) {
      margin-left: 0;
    }

    .sign-out-button-container {
      display: flex;

      @media (max-width: 600px) {
        justify-content: center;
        margin-left: -5%;
      }

      .sign-out {
        @include input;
        span {
          margin: 0;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        align-items: center;

        width: 100%;
        margin: 117px 0 30px 0;
        background-color: #f2032e;
        border-radius: 30px;
        color: #ffffff;
        font-weight: 700;
        cursor: pointer;
        max-width: 200px;

        @media (max-width: 600px) {
          margin-top: 30px;
        }
      }
    }

    .user-data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin: 12.5px 0 12.5px 0;
      font-size: 16px;
      color: #939393;

      span {
        margin: 10px 0 10px 20px;
      }
    }

    .user-page-content {
      max-width: 395px;
      width: 100%;

      @media (max-width: 600px) {
        max-width: 100%;
      }
    }

    .user-password {
      max-width: 395px;
      width: 100%;

      @media (max-width: 600px) {
        max-width: 100%;
      }

      .update-details-button {
        width: 127px;
        min-height: 30px;
        background-color: #30c9eb;
        border-radius: 30px;
        outline: none;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 16px;

        span {
          margin: 0;
        }
      }

      .user-data {
        &.button {
          align-items: center;
          width: 95%;
        }

        .input-password {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          div {
            display: flex;
            .eye {
              margin-left: -34px;
              width: 20px;
              height: 20px;
              cursor: pointer;
              z-index: 1;
            }
          }
        }
      }
    }

    .user-info {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      flex-wrap: wrap;
    }
  }
}

