.employees-list-wrapper {

  .employees-list-table-wrapper {
    max-height: calc(100vh - 500px);
    overflow-y: auto;

    td:nth-child(1), td:nth-child(4), td:nth-child(5), td:nth-child(6), th:nth-child(1), th:nth-child(4), th:nth-child(5), th:nth-child(6) {
      flex: 2;
    }

    td:nth-child(2), td:nth-child(3), th:nth-child(2), th:nth-child(3) {
      flex: 1;
    }
  }

  .employees-list-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 80px;

    button {
      width: 180px;
    }

    .employees-list-admin-button-wrapper {
      display: flex;

      .employees-list-admin-send-email-wrapper {
        display: flex;
        font-weight: 400;
        font-size: 16px;
        align-items: center;
        color: #5D656D;

        .send-on-email-wrapper {
          display: flex;
          margin: 0 32px;

          .employees-list-admin-send-email-options {
            display: flex;
            margin-left: 16px;
            cursor: pointer;
            text-transform: uppercase;

            .active {
              color: #00A6FB;
            }
          }
        }
      }
    }
  }
}

main.rotate {
  .send-on-email-wrapper {
    transform: rotateY(180deg);
    flex-direction: row-reverse;

    .employees-list-admin-send-email-options {
      display: flex;
      margin-right: 16px;
      margin-left: 0;
    }
  }
}