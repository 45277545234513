.supplier-details-wrapper {
  .supplier-details-tabs-wrapper {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .supplier-details-button-container {
      display: flex;

      button + button {
        margin-left: 16px;
      }
    }
  }

  .supplier-details-content {
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    padding: 32px;
    margin-right: 16px;
    box-sizing: border-box;
    margin-bottom: 40px;

    .supplier-details {
      max-width: 800px;
    }

  }
}