.salaries-page-wrapper {
  padding: 0 16px;

  .salaries-header {
    margin: 40px 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .salaries-header-title {
      font-weight: 900;
      font-size: 18px;
      text-transform: uppercase;
      color: #061D39;
      display: flex;
      user-select: none;

      div {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      svg {
        margin-right: 4px;
      }

      .salaries-header-employee-done {
        font-weight: 600;
        font-size: 14px;
        padding: 4px;
        text-transform: capitalize;
        color: #2DBD78;
        border: 1px solid #2DBD78;
        border-radius: 4px;
        margin: 0 16px;
      }
    }
  }

  .salaries-page-content-wrapper {
    .salary-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .salary-header-button-wrapper {
        display: flex;

        button + button {
          margin-left: 16px;
        }
      }
    }

    td:nth-child(2), th:nth-child(2){
      max-width: 75px;
      min-width: 75px;
    }

    td:nth-child(4), th:nth-child(4){
      max-width: 95px;
      min-width: 95px;
    }

    td:nth-child(10), th:nth-child(10),
    td:nth-child(11), th:nth-child(11),
    td:nth-child(12), th:nth-child(12) {
      max-width: 40px;
      min-width: 40px;
    }

    td:nth-child(1), th:nth-child(1){
      flex: 4;
      min-width: 100px;
    }

    td:nth-child(9), th:nth-child(9) {
      flex: 6;
      min-width: 100px;
    }

    td:nth-child(3), td:nth-child(5), td:nth-child(6), td:nth-child(7),
    th:nth-child(3), th:nth-child(5), th:nth-child(6), th:nth-child(7){
      max-width: 100px;
      min-width: 100px;
    }

  td:nth-child(8),  , th:nth-child(8){
    max-width: 115px;
    min-width: 115px;
  }

  .table-checkbox-checked span {
      color: #00A6FB;
    }

    .table-checkbox-root {
      color: #5D656D;
      padding: 0;
    }

    .companies-salary-status {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      width: 78px;
      height: 24px;
      border-radius: 2px;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      background-color: #FFBC10;
    }

    .companies-salary-status.done {
      background-color: #2DBD78;
    }
  }
}

.import-message{
  margin-bottom: 16px;
}

//.import-message:last-child {
//  margin-top: 16px;
//}

main.rotate {
  .salaries-header-title {
    transform: rotateY(180deg);

    & > div {
      display: flex;
      flex-direction: row-reverse;

      svg {
        transform: rotateY(180deg);
      }
    }
  }
}

.confirm-dialog-wrapper.rotate {
  .dialog-content {
    transform: rotateY(180deg);
    text-align: end;
  }
}