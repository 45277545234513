.date-carousel-item-wrapper {
  display: flex;

  .date-carousel-item-button, .date-carousel-item-value {
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  .date-carousel-item-button {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .date-carousel-item-value {
    width: 100px;
    height: 40px;
    margin: 0 2px;
  }
}

main.rotate {
  .date-carousel-item-wrapper {
    transform: rotateY(180deg);
  }

  .month.date-carousel-item-wrapper {
    flex-direction: row-reverse;

    div:first-child, div:last-child {
      transform: rotate(180deg);
    }
  }
}
