@import "../../scss/mixins.scss";

.salary-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 75%;

  @media (max-width: 1200px) {
    max-width: 95%;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }

  .month-download-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .month {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 18px;
      margin-bottom: 15px;
      color: #3e3e3e;

      @media (max-width: 600px) {
        font-size: 16px;
      }

      .comma {
        margin-right: 4px;
      }

      .month-number {
        @include month-number;
      }
    }

    div {
      display: flex;
      flex-direction: row;
    }

    .download-salary-button {
      width: 90px;
      min-height: 30px;
      background-color: #30c9eb;
      border-radius: 30px;
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin: 0 0 10px 10px;

      .salary {
        font-size: 16px;
        color: #ffffff;
        justify-content: center;

        @media (max-width: 600px) {
          font-size: 12px;
        }
      }

      &.close {
        background-color: #adadad;
      }
    }

    @media (min-width: 600px) {
      .download-salary-button:hover {
        background-color: #c7c7c7;
      }
    }

    .download-salary-button:first-child {
      margin-left: 0;
    }

    .download-monthly-overview {
      @extend .download-salary-button;
      width: 160px;
    }
  }

  .paid-container {
    @extend .month-download-container;
    flex-direction: column;
    margin-top: 14px;

    .total {
      display: flex;
      justify-content: flex-end;
      font-size: 18px;
      color: #003476;
      font-weight: 700;
      margin: 0 15px 9px 0;

      @media (max-width: 600px) {
        font-size: 12px;
      }

      .value {
        margin: 0 5px 0 15px;
      }
    }
  }
}
