@import '../../scss/mixins.scss';

.home-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .home-page-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: sticky;
    top: 50px;
    width: 100%;
    padding: 30px 0;
    z-index: 1;

    .home-page-title {
      @include page-title();
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @media (max-width: 600px) {
      padding: 25px 0 50px 0;
    }

    .profile-button {
      @include profile-button();
      margin: 0;
      max-width: 400px;
      min-height: 60px;
      font-size: 22px;

      .user-avatar {
        height: 45px;
        width: 45px;
      }
    }
  }

  .contact-us {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 95%;
    // border: solid #efefef 1px;
    flex-wrap: wrap;
    max-height: 360px;
    margin: 30px 0;
    border-radius: 20px;
    font-size: 18px;
    box-shadow: 0px 10px 30px #e7f0f7;

    @media (max-width: 850px) {
      max-height: none;
    }

    .title {
      font-weight: 700;
      margin: -15px 0 0 10px;
      // background-color: #ffffff;
      width: fit-content;
      padding: 0 5px;
      color: #003476;
      font-size: 24px;
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px 0 20px 15px;
      max-width: 50%;
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      color: #003476;

      div {
        display: flex;
        align-items: center;

        div {
          flex-direction: column;
          align-items: flex-start;

          a {
            color: #00347bab;
            font-weight: 400;
          }
        }
      }

      .info-icon {
        height: 20px;
        width: 20px;
        margin-right: 10px;

        &.us {
          height: 30px;
          width: 30px;
        }
      }

      .info-text {
        font-size: 16px;
        color: #020204;
        font-weight: 400;
      }

      .row {
        display: flex;
        flex-direction: row;

        .info-text {
          margin-left: 5px;
        }
      }

      @media (max-width: 850px) {
        max-width: 95%;
      }
    }
  }

  .yeul-sachir {
    @extend .contact-us;
    max-height: 360px;

    @media (max-width: 1280px) {
      max-height: 400px;
    }

    @media (max-width: 850px) {
      max-height: none;
    }

    .bank-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 50%;
      width: 100%;

      .contact-info {
        max-width: none;
      }
    }
  }

  .mail-information {
    @extend .contact-us;
  }

  .bank-information {
    @extend .contact-us;
  }
}
