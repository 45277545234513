.rotate-text {
  display: flex;
  font-family: 'Arimo';
  text-align: end;
  transform: rotateY(180deg);
}

text {
  display: flex;
  font-family: 'Roboto';
}
