.invoice-config-wrapper {
  .invoice-config-items-wrapper {
    display: flex;
    justify-content: space-between;

    .invoice-config-item + .invoice-config-item {
      margin-left: 32px;
    }

    .invoice-config-item {
      display: flex;
      flex-direction: column;
      flex: 1;

      .invoice-config-label {
        font-weight: 400;
        font-size: 16px;
        color: #5D656D;
        padding: 10px 0;
      }

      .invoice-config-date-picker {
        min-width: 100px;
        width: 100%;
        background-color: #ffffff;

        input {
          padding: 8px;
        }
      }

      .invoice-config-currency {
        min-width: 100px;
        width: 100%;
        padding: 0;

        input, .MuiSelect-root {
          padding: 8px;
          background-color: #ffffff;
          border: none;
          border-radius: 4px;
        }
      }
    }
  }
}

main.rotate {
  .invoice-config-label {
    transform: rotateY(180deg);
    text-align: end;
  }
}