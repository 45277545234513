.user-companies-form-item {
  border-radius: 10px;
  border: 1px solid #EBEEF1;
  padding: 16px;

  .user-companies-form-divider {
    margin: 16px 0;
  }

  .user-companies-form-item-header {
    .vertically-center {
      display: flex;
      align-items: center;
    }

    .Mui-checked {
      .MuiIconButton-label {
        color: #00A6FB;
      }
    }
  }

  .user-companies-form-item-content {
    .grid-switch-wrapper {
      display: flex;
      align-items: flex-end;

      .company-item {
        padding: 4px 0;
        display: flex;

        .company-item-label {
          width: 100%;
          margin-bottom: 0;
          color: #5D656D;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          display: flex;
          align-items: center;
        }

        .switch-root {
          color: #00A6FB;
        }

        .switch-track {
          border-radius: 12px;
        }

        .switch-base {
          color: #fff !important;
        }

        .Mui-checked + .MuiSwitch-track {
          background-color: #00A6FB !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

.user-companies-form-item + .user-companies-form-item {
  margin-top: 16px;
}