.new-user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .new-user-buttons-container,  .new-user-indicator-wrapper, .new-user-form-container{
    max-width: 900px;
    width: 100%;
  }

  .new-user-indicator-wrapper {
    margin-bottom: 24px;
  }

  .new-user-form-container {
    max-width: 900px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 24px;
    box-sizing: border-box;
    height: calc(100vh - 300px);
    overflow-y: auto;
  }

  .new-user-buttons-container{
    display: flex;
    justify-content: space-between;
    margin: 16px 0 70px;

    button{
      width: 130px;
    }
  }
}