.monthly-report-footer {
  font-size: 16px;
  margin: 16px 0 24px;

  .monthly-report-footer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    font-weight: 500;
    padding: 0 16px;
  }

  .monthly-report-footer-item:last-child {
    font-weight: 800;
  }

  .monthly-report-footer-item:nth-child(2n+1) {
    background-color: white;
  }
}