.employees-report-wrapper {
  .employees-report-table-wrapper {
    td:nth-child(2), td:nth-child(3), th:nth-child(2), th:nth-child(3), {
      flex: 2;
    }

    td:nth-child(1), td:nth-child(4), th:nth-child(1), th:nth-child(4) {
      flex: 1;
    }

    td:nth-child(2) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .employees-report-table-wrapper.hourly-rate-table {
    td:nth-child(1), th:nth-child(1), td:nth-child(3), th:nth-child(3), td:nth-child(5), th:nth-child(5) {
      flex: 2;

    }

    td:nth-child(2), th:nth-child(2), {
      flex: 3;
    }

    td:nth-child(4), th:nth-child(4) {
      flex: 1;
    }
  }

  .employees-report-hourly-wrapper {
    display: flex;

    & > div + div{
      margin-left: 4px;
    }
  }

  .employees-report-hourly-input{
    width: 50px;

    input{
      padding: 4px;
    }
  }

  .employees-report-switcher-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    font-size: 18px;
    text-transform: uppercase;
    color: #061D39;
    user-select: none;

    div {
      display: flex;
      cursor: pointer;
    }

    div:first-child svg {
      margin-right: 4px;
    }

    div:last-child svg, {
      margin-left: 4px;
    }
  }

  .employees-report-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
  }
}