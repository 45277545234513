@import '../../scss/mixins.scss';

.auth-container {
  width: 100vw;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 16px;
  background-color: #F8F9FA;

  .auth-lng-change-wrapper {
    position: absolute;
    top: 32px;
    display: flex;
    justify-content: center;
    width: 257px;

    .lng-change-container {
      @include lng-change-container;
      width: 136px;

      button {
        width: 68px;
      }
    }
  }

  @media (max-width: 600px) {
    width: 100vw;
    background-color: #FFFFFF;
  }

  .form-head {
    display: flex;
    position: relative;
    align-items: center;

    .back-arrow {
      cursor: pointer;
    }

    span {
      width: 100%;

      .app-logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 600px) {
          display: none;
        }
      }
    }
  }

  .content-wrapper {
    width: 100%;
    text-align: center;
  }

  .resend-password {
    color: #00A6FB;
    font-size: 16px;
    cursor: pointer;
  }

  .auth-wrapper {
    background: #ffffff;
    z-index: 1;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    @media (max-width: 600px) {
      width: 100vw;
    }
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    &.margin-top--20 {
      margin-top: 20px;
    }

  }

  .lng-change-container {
    @include lng-change-container;
    position: absolute;
    top: 0;
    margin: 20px 0 0 0;
    right: 5%;
    font-size: 16px;
    z-index: 1;

    @media (min-width: 600px) {
      display: none;
    }
  }

  .image-half {
    width: 50vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
  }

  form {
    height: 100%;
    width: 100%;
    min-width: 600px;
    padding: 40px 40px 32px;
    box-sizing: border-box;

    @media (max-width: 600px) {
      border-radius: 0;
      min-width: unset;
      z-index: 3;
      box-shadow: none;
    }

    .fields-container {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;

      input[type='email'],
      input[type='text'] {
        @include input;
        width: 100%;
        font-weight: 700;
      }

      .identity-number {
        display: flex;
        justify-content: flex-start;
        color: #939393;
      }

      .didnt-get-code {
        display: flex;
        align-items: center;
        gap: 3px;
      }

      .email {
        @extend .identity-number;
        color: #939393;
        margin-bottom: 0;
      }

      .password {
        @extend .identity-number;
        margin-bottom: 0;
        color: #939393;
        margin-top: 20px;
      }

      .password-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        input[type='password'],
        input[type='text'] {
          @include input;
          font-weight: 700;
          margin-top: 0;
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          .eye {
            position: absolute;
            margin-left: -50px;
            width: 20px;
            height: 20px;
            cursor: pointer;
            z-index: 1;
          }
        }
      }
    }

    .forgot-password {
      text-decoration-line: underline;
      color: #00A6FB;
      display: flex;
      justify-content: center;
      outline: none;
      border: none;
      background: none;
      cursor: pointer;
    }

    input[type='submit'] {
      @include input;
      padding: 12px 24px;
      width: 100%;
      margin-top: 24px;
      background: #00A6FB;
      border-radius: 4px;
      color: #ffffff;
      font-weight: 700;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &.margin-top--50 {
        margin-top: 50px;
      }
    }

    .app-logo {
      @include app-logo();
      margin: 0 auto;
    }

    .error {
      @include error();
    }

    .additional-links {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      margin-top: 8px;

      .otp-link-wrapper {
        display: flex;
        align-items: center;
        gap: 3px;
      }
    }

    .dont-have-account {
      color: #061D39;
      margin: 32px 0 0 0;
    }

    .sign {
      @extend .forgot-password;
      justify-content: center;
      margin: 10px auto 0;
      font-size: 16px;
    }

    .contact-us {
      @extend .dont-have-account;
      color: #061D39;
    }

    .contact {
      color: #00A6FB;

      &.number {
        color: #5D656D;
      }

    }
  }

  .bottom {
    box-sizing: border-box;
    padding: 32px 40px;
    border-top: 1px solid #EBEEF1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }
}
