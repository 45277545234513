.monthly-report-page-wrapper {
  padding: 0 16px;

  .monthly-report-header {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .monthly-report-header-title {
      font-weight: 900;
      font-size: 18px;
      text-transform: uppercase;
      color: #061D39;
      display: flex;
      user-select: none;

      div {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      svg {
        margin-right: 4px;
      }
    }
  }

  td, th {
    padding: 0 16px;
  }
}

main.rotate {
  .monthly-report-footer-item div, .monthly-report-header-table-info-item div, .monthly-report-header-title {
    transform: rotateY(180deg);

    & > div {
      display: flex;
      flex-direction: row-reverse;

      svg {
        transform: rotateY(180deg);
      }
    }
  }

  .monthly-report-employee-table-rate-wrapper {
    transform: rotateY(180deg);

    div {
      transform: rotateY(180deg);
    }
  }

  .monthly-report-employee-switcher-wrapper {
    transform: rotateY(180deg);
  }
}
