.receipts-table-container {
  th:last-child {
    color: #EBEEF1;
  }

  td:nth-child(7), th:nth-child(7) {
    flex: 1
  }

  td:nth-child(1), th:nth-child(1), td:nth-child(2), th:nth-child(2), td:nth-child(4), th:nth-child(4),
  td:nth-child(5), th:nth-child(5) {
    flex: 2
  }

  td:nth-child(3), th:nth-child(3), td:nth-child(6), th:nth-child(6) {
    flex: 3
  }
}