main {
  display: flex;
  justify-items: center;
  align-self: center;
  flex-direction: row;
  background-color: #F8F9FA;

  .content {
    position: relative;
    width: calc(100vw - 257px);

    @media (max-width: 600px) {
      padding-left: 0;
      height: 100vh;
      overflow-y: scroll;
      width: 100%;
    }

    .clickable-block {
      color: #00A6FB;
      width: 100%;
    }

    .clickable-block:hover {
      text-decoration: underline;
    }
  }

  &.rotate {
    transform: rotateY(180deg);
    @media (max-width: 600px) {
      overflow-x: hidden;
      height: 100vh;
    }

    input {
      transform: rotateY(180deg);
      text-align: right;
    }
  }
}
