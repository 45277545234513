@import '../../scss/mixins.scss';

.accordion {
  max-width: 75%;

  @media (max-width: 600px) {
    max-width: 100%;
  }

  span {
    display: flex;
    align-items: center;
  }
}

.month-number {
  @include month-number;
  padding: 5px;
  margin-left: 10px;
  height: 30px;
  width: 30px;
  font-size: 16px;
}
