.progress-indicator-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .progress-indicator-divider {
    background-color: #EBEEF1;
    top: 21px;
    position: absolute;
    width: calc(100% - 32px);
    margin: 0 16px;
  }

  .progress-indicator-item {
    display: flex;
    align-items: center;
    color: #EBEEF1;
    padding: 0 8px;
    position: relative;
    background-color: #F8F9FA;

    .progress-indicator-item-index {
      width: 40px;
      height: 40px;
      border: 2px solid #EBEEF1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;
    }

    .progress-indicator-item-label {
      font-weight: 900;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }

  .progress-indicator-item.active {
    .progress-indicator-item-index {
      border: 2px solid #00A6FB;
      background-color: #00A6FB;
      color: #FFFFFF;
    }

    .progress-indicator-item-label {
      color: #00A6FB;
    }
  }

  .progress-indicator-item.before {
    cursor: pointer;

    .progress-indicator-item-index {
      border: 2px solid #00A6FB;
      color: #00A6FB;
    }

    .progress-indicator-item-label {
      color: #00A6FB;
    }
  }
}

main.rotate {
  .progress-indicator-item-label, .progress-indicator-item-index {
    transform: rotateY(180deg);
  }
}