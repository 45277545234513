.company-users-details-item {
  border-radius: 10px;
  border: 1px solid #EBEEF1;
  padding: 16px;

  .company-users-details-divider {
    margin: 16px 0;
  }

  .company-users-details-item-header {
    .vertically-center {
      display: flex;
      align-items: center;
    }

    .Mui-checked {
      .MuiIconButton-label {
        color: #00A6FB;
      }
    }
  }

  .company-users-details-item-content {
  }
}

.company-users-details-item + .company-users-details-item {
  margin-top: 16px;
}


main.rotate {
  .company-users-details-item-header {
    .company-users-details-item-title {
      transform: rotateY(180deg);
      justify-content: flex-end;
    }
  }
}
