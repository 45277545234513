.company-suppliers-details-wrapper {
  .agents-table-container {
    margin-top: 16px;

    td:nth-child(1), th:nth-child(1) {
      flex: 1;
    }

    td:nth-child(3), th:nth-child(3), td:nth-child(4), th:nth-child(4) {
      flex: 4;
    }

    td:nth-child(2), th:nth-child(2) {
      flex: 6;
    }

    th:nth-child(1) {
      color: #EBEEF1;
    }


    .MuiCheckbox-root {
      padding: 0;
    }

    .Mui-checked {
      .MuiIconButton-label {
        color: #00A6FB;
      }
    }
  }
}