@import '../../scss/mixins.scss';

.header-container {
  display: none;
}

@media screen and (max-width: 600px) {
  .header-container {
    width: 100%;
    height: 50px;
    z-index: 1;
    background-color: #ffffff;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    margin-right: 55px;
    justify-content: space-between;

    .nav-bar-icon {
      @include nav-bar-icon;
      margin: 10px 0 0 5%;
    }

    .image-half {
      width: 50vw;
      height: 100vh;
      object-fit: cover;
    }
  }
}

.auth-header-container {
  @extend .header-container;
  background-color: transparent;

  @media screen and (max-width: 600px) {
    background-color: #FFFFFF;
  }
}
