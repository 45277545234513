.agent-documents-wrapper {
  .agent-documents-tabs-wrapper {
  }

  .agent-documents-content {
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    padding: 8px 0;
    margin-right: 16px;
    box-sizing: border-box;
    margin-bottom: 40px;
  }
}