.agents-list-wrapper {
}

.employees-table-container {
  td:nth-child(1), td:nth-child(4), th:nth-child(1), th:nth-child(4) {
    flex: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  td:nth-child(2), td:nth-child(3), td:nth-child(5), th:nth-child(2), th:nth-child(3), th:nth-child(5) {
    flex: 1;
  }

  td:first-child div {
    width: 100%;
  }

  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 78px;
    height: 24px;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    border-radius: 2px;
  }

  .active {
    background-color: #2DBD78;
    font-size: 16px;
  }

  .dismissed {
    background-color: #D72650;
    font-size: 16px;
  }

  @media (max-width: 600px) {
    .status {
      width: auto;
      font-size: 12px;
    }
  }
}