.details-wrapper {
  width: 100%;

  .details-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #5D656D;
    margin-bottom: 8px;
  }

  .details-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #061D39;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

main.rotate {
  .details-value, .details-label{
    transform: rotateY(180deg);
    text-align: right;
  }
}