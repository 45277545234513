@import "../../scss/mixins.scss";

table {
  border-radius: 10px 10px 0 0;
  width: 100%;
  font-size: 16px;
  border-collapse: collapse;
  border: none;
  table-layout: fixed;
  line-height: 30px;

  tbody {
    tr:nth-child(odd) {
      background-color: #fbfbfc;
    }

    tr:nth-child(even) {
      background-color: #fefeff;
    }

    tr:hover {
      background-color: rgb(243, 243, 243);
    }

    td {
      justify-content: flex-start;
      color: #061D39;
      padding: 0 3px;
      font-size: 16px;
      min-height: 30px;

    }
  }

  thead {
    th {
      font-weight: 400;
      display: flex;
      padding: 0 3px;
      font-size: 16px;
      min-height: 30px;
      color: #061D39;
      background-color: #EBEEF1;

    }
  }

  @media (max-width: 600px) {
    line-height: inherit;
    font-size: 10px;
  }

  a {
    text-decoration: unset;
  }

  tr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 30px;

    .date {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-left: 15px;
      word-break: break-word;
      max-width: 150px;

      @media (max-width: 600px) {
        max-width: 60px;
      }
    }

    .number {
      @extend .date;
      margin-left: 0;
      max-width: 150px;

      @media (max-width: 600px) {
        max-width: 45px;
      }
    }

    .notes {
      @extend .date;
      margin-left: 0;
      max-width: 100px;

      @media (max-width: 600px) {
        max-width: 45px;
      }
    }

    .origin-amount {
      @extend .date;
      margin-left: 0;
      max-width: 60px;

      @media (max-width: 600px) {
        max-width: 45px;
      }
    }

    .rate {
      @extend .date;
      justify-content: center;
      margin-left: 0;
      max-width: 50px;

      @media (max-width: 600px) {
        max-width: 35px;
      }
    }

    .currency {
      @extend .date;
      justify-content: center;
      margin-left: 0;
      max-width: 65px;

      @media (max-width: 600px) {
        max-width: 20px;
      }
    }

    .type {
      @extend .date;
      margin-left: 0;

      &.text {
        a {
          display: flex;
        }
        text-decoration-line: underline;
        color: #174e81;
      }

      .check-box {
        margin: 0 5px;
      }

      @media (max-width: 600px) {
        max-width: 60px;
      }
    }

    .customer {
      @extend .date;
      margin-left: 0;
      max-width: 100%;
      width: 100%;
    }

    .amount {
      @extend .date;
      margin-left: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-right: 15px;
      min-width: 100px;
      max-width: 120px;

      @media (max-width: 600px) {
        max-width: 90px;
        min-width: 70px;
      }

      div {
        display: flex;
        flex-direction: row;

        .space {
          margin-left: 3px;
        }
      }

      img {
        @media (max-width: 600px) {
          height: 12px;
          width: 12px;
        }
      }
    }

    td {
      cursor: pointer;
      color: #020204;
      margin: 2px 1px;
    }
  }
}
