.accounts-search-field-wrapper {
  margin: 16px 0;

  .accounts-search-field-input {
    width: 100%;

    .MuiInputBase-root {
      background-color: #F8F9FA;
      border: 1px solid #EBEEF1
    }

    input{
      padding: 8px;
    }
  }

  .search-message{
    width: 100%;
    padding-bottom: 16px;
  }
}

main.rotate {
  .search-message{
    transform: rotateY(180deg);
    text-align: end;
  }
}