.editable-input-wrapper {
  width: 100%;
  min-height: 30px;

  .editable-input-field{
    background-color: #EBEEF1;
    width: 100%;
  }
}

.editable-input-wrapper:hover {
  background-color: #EBEEF1;
}