.monthly-report-header-table-info-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  .monthly-report-header-table-info-item {
    margin: 40px 0 24px;
    display: flex;
  }

  .monthly-report-header-table-info-title {
    margin-right: 4px;
    font-weight: 600;
  }

  div + .monthly-report-header-table-info-title {
    margin-left: 32px;
  }
}