.monthly-report-employee-table-rate-button + .monthly-report-employee-table-rate-button {
  margin-left: 4px;
}

.monthly-report-employee-table-rate-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: #CED3DE;
  border-radius: 2px;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  user-select: none;
}

.monthly-report-employee-table-rate-button.active {
  background: #2DBD78;
}