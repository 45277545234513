.terms-and-conditions-date-picker {
  min-width: 100px;
  width: 100%;
  background-color: #ffffff;

  .MuiInputBase-root {
    background-color: #F8F9FA;
    border: 1px solid #EBEEF1
  }

  input {
    padding: 8px;
  }
}

.terms-and-conditions-divider{
  margin: 16px 0;
  width: 100%;
  background-color: #EBEEF1;
}