.tabs {
  .tab-content-wrapper {
  }

  .tab {
    display: flex;
    font-size: 16px;
    color: #061D39;
    text-transform: none;
    align-items: center;
    font-weight: 500;

    .items-count {
      padding: 0 10px;;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      background-color: #5F7081;
      border-radius: 4px;
      margin-left: 4px;
    }
  }

  :not(.selected-tab).tab-root:hover {
    background-color: #EBEEF1;
    border-radius: 5px 5px 0 0;
  }

  .tab-root {
    border-bottom: 2px solid #F8F9FA;
    min-width: unset;

  }
  .selected-tab.tab-root {
    padding: 6px 0;
    margin: 0 12px;
  }

  .tab-root:first-child {
    margin-left: 0;
    padding-left: 0;
  }

  .tab-root:last-child {
    margin-right: 0;
  }

  .selected-tab {
    border-bottom: 2px solid #00A6FB;

    .tab {
      color: #00A6FB;
    }
  }

  .selected-tab .items-count {
    background-color: #00A6FB;
  }

  .MuiTabs-indicator {
    display: none;
    background-color: #00A6FB !important;
  }
}

main.rotate {
  .tab span {
    transform: rotateY(180deg);
  }
}