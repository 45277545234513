@import '../../scss/mixins.scss';

.menu-container {
  .nav-bar {
    width: 257px;
    height: 100vh;
    background-color: #ffffff;
    border-right: #efefef solid 1px;
    left: 0;
    transition: left 1s;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    transform: rotateY(0);
    overflow-y: auto;

    @media (max-width: 600px) {
      position: fixed;
      z-index: 2;
      left: 0;
    }

    .nav-bar-header {
      display: flex;
      padding-top: 28px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      @media (max-width: 600px) {
        height: 50px;
        padding-top: 0;
      }

      .nav-bar-icon {
        @include nav-bar-icon;
        margin: 0;
      }

      .lng-change-container {
        @include lng-change-container;
      }

      .app-logo {
        width: 200px;
        cursor: pointer;
      }
    }

    .buttons-container {
      margin-top: 34px;
      margin-bottom: 34px;
      height: calc(100% - 210px);
      overflow: auto;

      .menu-item {
        display: flex;
        color: #494949;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        width: 200px;
        margin-bottom: 34px;
        align-items: center;
        padding: 7px 0;

        .menu-icon {
          width: 30px;
          height: 20px;
        }

        &.active {
          color: #00A6FB;

          svg path {
            fill: #00A6FB;
          }
        }
      }

      .menu-item:hover {
        background-color: rgb(243, 243, 243);
        border-radius: 35px;
      }

      .top-margin-menu-item {
        margin-top: 34px;
      }

      .home-page {
        @extend .menu-item;
      }

      .files {
        display: flex;
        margin-top: 29px;
        font-weight: 700;
        font-size: 16px;
        color: #cfcfcf;
      }

      .invoices {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        outline: none;
        background: none;
        border: none;
        cursor: pointer;
        color: #939393;
        font-size: 16px;
        padding: 0;
        margin: 35px 0 30px 0;
        font-weight: 700;

        img {
          margin-right: 15.33px;
        }
      }

      .expenses {
        @extend .invoices;
        margin: 30px 0;
      }

      .salaries {
        @extend .invoices;
        margin: 30px 0;
      }
    }

    @media (max-width: 600px) {
      .buttons-container {
        height: calc(100% - 110px);
      }
    }

    &.close {
      height: 100vh;
      left: -257px;
      transition: left 1s;
    }

    &.disappear {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }

  .nav-bar-footer {
    position: relative;
    width: 100%;
    height: 44px;

    .lng-change-wrapper {
      position: absolute;
      bottom: 0px;
      display: flex;
      justify-content: center;
      width: 240px;
      align-items: flex-start;
      height: 44px;
      background-color: #ffffff;

      .lng-change-container {
        @include lng-change-container;
        width: 136px;

        button {
          width: 68px;
        }
      }
    }
  }
}
