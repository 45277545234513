.table-container {
  max-width: 100%;
  font-size: 16px;
  border-collapse: collapse;
  overflow-y: auto;

  table {
    table-layout: auto;
  }

  tbody {
    border: 1px solid #EBEEF1;
  }

  tr:first-child {
    border-top: 1px solid #EBEEF1;
  }

  tr {
    border-bottom: 1px solid #EBEEF1;
  }

  th {
    color: #061D39;
    font-weight: 400;
    background-color: #EBEEF1;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td {
    justify-content: flex-start;
    color: #061D39;
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
    margin: 0px;
    flex: 1;
  }

  td, th {
    padding: 0 3px;
    font-size: 16px;
    line-height: 24px;
  }

  td:nth-child(1), th:nth-child(1) {
    display: flex;
  }

  tr {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
  }

  tr:nth-child(2n) {
    background-color: #fefeff;
  }

  tr:nth-child(2n+1) {
    background-color: #fbfbfc;
  }

  tr:hover {
    background-color: rgb(243, 243, 243);
  }
}

.pagination-status {
  display: flex;
  padding-left: 0;
}

.pagination-status__item {
  display: block;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 32px;
    height: 32px;
    color: #5F7081;
    background: #fefeff;
    border: 1px solid #fefeff;
    border-radius: 0;
    cursor: pointer;
    font-size: 16px;
  }

  .pagination-status__btn--active {
    background: #AFB4C1;
  }
}

main.rotate .table-container td, main.rotate .table-container th {
  transform: rotateY(180deg);
  text-align: right;
  justify-content: right;
  display: flex;
}


main.rotate .table-container .pagination-status__item:not(:first-child):not(:last-child) {
  button {
    transform: rotateY(180deg);
  }
}
