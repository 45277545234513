.employees-page-wrapper {
  padding: 0 16px;

  .employees-header {
    margin: 40px 0;
    font-weight: 900;
    font-size: 18px;
    text-transform: uppercase;
    color: #061D39;
    cursor: pointer;
    width: 100%;
  }

  .employees-header > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .employees-header-full-width {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        display: flex;
      }

      button {
        text-transform: initial;
      }

      .push-button {
        margin-right: 16px;
      }

      .sync-button {
        margin-left: 16px;
      }
    }

    .employees-header-left-part {
      display: flex;
      align-items: center;
    }

    .employees-header-right-part {
      display: flex;

      button {
        margin: 0 16px;
        text-transform: none;
      }
    }

    .employees-page-status-input {
      border-radius: 4px;

      &.active {
        background-color: #2DBD78;
        font-size: 16px;
      }

      &.dismissed {
        background-color: #D72650;
        font-size: 16px;
      }

      input, .MuiSelect-root {
        padding: 8px;
        width: 100px;
        color: #fff;
        border: none;
      }

      fieldset {
        border: none;
      }

      svg {
        color: white;
      }
    }
  }

  .employees-tabs-wrapper {
    padding: 0 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .employees-switch-wrapper {
    display: flex;
    align-items: center;

    .employees-form-item {
      padding: 4px 0;
      display: flex;
      align-items: center;

      .employees-form-item-label {
        width: 100%;
        color: #5D656D;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 0;
      }

      .switch-root {
        color: #00A6FB;
      }

      .switch-track {
        border-radius: 12px;
      }

      .switch-base {
        color: #fff !important;
      }

      .Mui-checked + .MuiSwitch-track {
        background-color: #00A6FB !important;
        opacity: 1 !important;
      }
    }
  }
}

main.rotate {
  .employees-header > div {
    .employees-header-left-part {
      transform: rotateY(180deg);
      display: flex;
      flex-direction: row-reverse;

      svg {
        transform: rotateY(180deg);
      }
    }

    .employees-header-full-width {
      button {
        transform: none; //???
        span {
          transform: rotateY(180deg);
        }
      }

      .employees-header-full-width-title {
        transform: rotateY(180deg);
      }
    }
  }

  .employees-switch-wrapper {
    .employees-form-item-label {
      transform: rotateY(180deg);
      display: flex;
    }
  }
}

.confirm-dialog-wrapper.rotate {
  .dialog-content {
    transform: rotateY(180deg);
    text-align: end;
  }
}