.client-agents-details-wrapper {
  .client-agents-details-search-message {
    padding: 8px 0;
    font-size: 16px;
  }

  .agents-table-container {
    margin-top: 16px;
  }

  .agents-table-container.edit {
    td:nth-child(1), th:nth-child(1) {
      flex: 1;
    }

    td:nth-child(3), th:nth-child(3), td:nth-child(4), th:nth-child(4) {
      flex: 4;
    }

    td:nth-child(2), th:nth-child(2) {
      flex: 6;
    }

    th:nth-child(1) {
      color: #EBEEF1;
    }


    .MuiCheckbox-root {
      padding: 0;
    }

    .Mui-checked {
      .MuiIconButton-label {
        color: #00A6FB;
      }
    }
  }

  .agents-table-container.view {
    td:nth-child(1), th:nth-child(1) {
      flex: 3;
    }

    td:nth-child(2), th:nth-child(2), td:nth-child(3), th:nth-child(3) {
      flex: 2;
    }
  }

  .empty-list {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
}

main.rotate {
  .client-agents-details-search-message {
    transform: rotateY(180deg);
    width: 100%;
    text-align: end;
  }

  .empty-list{
    transform: rotateY(180deg);
  }
}

main.rotate {
  .client-agent-item-name {
    transform: rotateY(180deg);
    text-align: end;
  }
}