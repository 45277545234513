.input-component-item {
  padding: 4px 0;

  .input-component-item-label {
    width: 100%;
    margin-bottom: 10px;
    color: #5D656D;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }

  .input-component-item-input {
    width: 100%;

    input, .MuiSelect-root {
      padding: 8px;
      background-color: #F8F9FA;
      border: 1px solid #EBEEF1
    }
  }
}

main.rotate {
  .input-component-item-label {
    transform: rotateY(180deg);
    justify-content: flex-end;
  }

  .MuiSelect-select {
    transform: rotateY(180deg);
  }

  .MuiSelect-iconOutlined {
    right: unset;
    left: 7px;
  }

  .input-component-item-label {
    transform: rotateY(180deg);
    display: flex;
    margin: 0;
  }

  textarea {
    transform: rotateY(180deg);
    text-align: end;
  }
}
