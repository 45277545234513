.company-salary-wrapper {
  .salary-table-divider{
    margin: 16px 0;
  }

  .income-salary-table-wrapper + .outcome-salary-table-wrapper {
    margin-top: 40px;
  }

  .outcome-salary-table-wrapper, .income-salary-table-wrapper {
    .table-checkbox-checked-header {
      margin-left: 1px;
    }

    .company-salary-table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .company-salary-table-header-title {
        font-size: 18px;
        color: #5D656D;

        span {
          font-weight: 600;
          margin: 0 0 0 4px;
        }
      }

      .company-salary-table-header-button-wrapper {
        display: flex;

        button + button {
          margin-left: 8px;
        }
      }
    }
  }

  .income-table-wrapper, .outcome-table-wrapper, .salary-done-income-table-wrapper, .salary-done-outcome-table-wrapper {
    overflow-y: auto;
    max-height: 200px;
    height: 200px;
  }

  .outcome-salary-table-wrapper {
    margin-bottom: 32px;
  }

  .table-checkbox-checked span {
    color: #00A6FB;
  }

  .table-checkbox-root {
    color: #5D656D;
    padding: 0;
  }

  .company-salary-status {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 78px;
    height: 24px;
    border-radius: 2px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    background-color: #FFBC10;
  }

  .company-salary-status.paid {
    background-color: #2DBD78;
  }

  .company-salary-footer {
    display: flex;
    justify-content: flex-end;

    button + button {
      margin-left: 8px;
    }
  }
}

.employee-salary-dialog-wrapper{
  max-width: 600px;
}

main.rotate {
  .company-salary-table-header-title {
    transform: rotateY(180deg);
    flex-direction: row-reverse;
    display: flex;

    span{
      margin: 0 0 0 4px;
    }
  }
}