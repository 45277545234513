.confirm-dialog-wrapper {
  max-width: 600px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 10px;

  .confirm-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
    border-bottom: 1px solid #DADEE2;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #061D39;

    .confirm-dialog-title {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 5px;
    }

    button {
      min-width: 0;
    }
  }

  .confirm-dialog-content {
    padding: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5F7081;

    p {
      margin: 0;
    }

    p + p {
      margin-top: 8px;
    }
  }

  .confirm-dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 32px 32px;
  }
}

.confirm-dialog-wrapper.rotate {
  transform: rotateY(180deg);

  .confirm-dialog-title {
    transform: rotateY(180deg);
  }

  .confirm-dialog-content p {
    transform: rotateY(180deg);
    text-align: end;
  }
}