.invoices-table-container {
  th:last-child{
    color: #EBEEF1;
  }

  td:nth-child(5), th:nth-child(5), td:nth-child(8), th:nth-child(8), td:nth-child(9), th:nth-child(9) {
    flex: 1;
  }

  td:nth-child(1), th:nth-child(1), td:nth-child(2), th:nth-child(2), td:nth-child(4), th:nth-child(4),
  td:nth-child(6), th:nth-child(6), td:nth-child(7), th:nth-child(7) {
    flex: 2
  }

  td:nth-child(3), th:nth-child(3) {
    flex: 3
  }

  .table-checkbox-checked span {
    color: #00A6FB;
  }

  .table-checkbox-root {
    color: #5D656D;
    padding: 0;
  }
}