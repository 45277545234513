.grid-switch-wrapper {
  display: flex;
  align-items: flex-end;

  .company-item {
    padding: 4px 0;
    display: flex;
    align-items: center;

    .company-item-label {
      width: 100%;
      margin-bottom: 0;
      color: #5D656D;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }

    .switch-root {
      color: #00A6FB;
    }

    .switch-track {
      border-radius: 12px;
    }

    .switch-base {
      color: #fff !important;
    }

    .Mui-checked + .MuiSwitch-track {
      background-color: #00A6FB !important;
      opacity: 1 !important;
    }
  }
}

main.rotate {
  .company-item-label {
    transform: rotateY(180deg);
  }
}