.expenses-table-container {
  td:nth-child(1), th:nth-child(1),
  td:nth-child(9), th:nth-child(9),
  td:nth-child(13), th:nth-child(13) {
    flex: 1;
  }


  td:nth-child(6), th:nth-child(6),
  td:nth-child(7), th:nth-child(7),
  td:nth-child(8), th:nth-child(8),
  td:nth-child(10), th:nth-child(10),
  td:nth-child(11), th:nth-child(11)
  {
    flex: 2
  }

  td:nth-child(4), th:nth-child(4),
  td:nth-child(5), th:nth-child(5) {
    flex: 3
  }

  td:nth-child(2), th:nth-child(2),
  td:nth-child(3), th:nth-child(3),
  td:nth-child(12), th:nth-child(12) {
    flex: 4
  }

  .table-checkbox-checked span {
    color: #00A6FB;
  }

  .table-checkbox-root {
    color: #5D656D;
    padding: 0;
  }
}