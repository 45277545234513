.employee-salary-footer {
  font-size: 16px;
  margin: 16px 0 24px;

  .employee-salary-footer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    font-weight: 400;
    padding: 0 16px;
  }

  .employee-salary-footer-item:last-child {
    font-weight: 800;
  }

  .employee-salary-footer-item:nth-child(2n+1) {
    background-color: white;
  }
}

main.rotate {
  .employee-salary-footer-item {
    transform: rotateY(180deg);
    display: flex;
    flex-direction: row-reverse;
  }
}