.button-switcher-wrapper {
  display: flex;

  .button-switcher-item:first-child {
    border-radius: 4px 0 0 4px;
  }

  .button-switcher-item:last-child {
    border-radius: 0 4px 4px 0;
  }

  .button-switcher-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EBEEF1;
    text-transform: uppercase;
    color: #003164;
    min-width: 80px;
    height: 40px;
    cursor: pointer;
    width: 100%;
  }

  .button-switcher-item.active {
    background: #00A6FB;
    color: #FFFFFF;
  }
}

main.rotate {
  .button-switcher-item {
    transform: rotateY(180deg);
  }

  .button-switcher-item:first-child {
    border-radius: 0 4px 4px 0;
  }

  .button-switcher-item:last-child {
    border-radius: 4px 0 0 4px;
  }

}