.companies-list-wrapper {
  .tabs-wrapper {
    padding: 24px 0;
  }

  td:nth-child(1), th:nth-child(1) {
    flex: 3;
  }

  td:nth-child(2), th:nth-child(2), td:nth-child(3), th:nth-child(3), td:nth-child(4), th:nth-child(4) {
    flex: 1;
  }

  .tab-content-wrapper {
    .companies-list-report-status {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      width: 78px;
      height: 24px;
      border-radius: 2px;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      background-color: #FFBC10;
    }

    .companies-list-report-status.ready {
      background-color: #2DBD78;
    }
  }
}