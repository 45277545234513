.search-root {
  width: 100%;
  background-color: #AFB4C1;
  border-radius: 4px 4px 0 0;

  input {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    padding: 8px 4px;
  }

  input::placeholder {
    color: #ffffff;
    opacity: unset;
  }
}

.search-underline::before, .search-underline::after {
  border-bottom: none !important;
}

.search-icon {
  margin-left: 16px;
}

.close-icon{
  margin-right: 16px;
  cursor: pointer;
}

