@import "../../scss/mixins.scss";

.admin-panel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .user-documents-wrapper{
    width: 95%;
  }

  .admin-panel-header {
    margin: 40px 0 24px;
    display: flex;
    align-items: center;
    width: 95%;

    .admin-panel-header-title {
      font-weight: 900;
      font-size: 18px;
      text-transform: uppercase;
      color: #061D39;
    }
  }

  .autocomplete-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 60%;

    @media (max-width: 600px) {
      width: 100%;
    }

    .autocomplete {
      width: 100%;
    }

    .search-button {
      width: 120px;
      height: 40px;
      cursor: pointer;
      background-color: $primary-color;
      margin: 23px 10px 0 10px;
    }
  }

  .salary-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 50px;
    margin-left: 5%;

    @media (max-width: 600px) {
      margin-left: 0;
      width: 95%;
    }

    .current-year {
      font-weight: 900;
      font-size: 48px;
      color: $primary-color;
      margin-bottom: 50px;
    }
  }

  .error-message {
    @extend .salary-content;
    color: $error-color;
  }
}

main.rotate {
  .admin-panel-header-title {
    transform: rotateY(180deg);

    & > div {
      display: flex;
      flex-direction: row-reverse;

      svg {
        transform: rotateY(180deg);
      }
    }
  }
}
