button.yeuls-button {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  font-weight: 700;
  font-size: 16px;
  text-transform: unset;
}

button.yeuls-button.standard {
  border: 2px solid #00A6FB;
  background: #00A6FB;
  color: #FFFFFF;
}

button.yeuls-button.outlined {
  border: 2px solid #00A6FB;
  background: #FFFFFF;
  color: #00A6FB;
}

button.yeuls-button.negative, button.yeuls-button.Mui-disabled{
  border: 2px solid #AFB4C1;
  background: #AFB4C1;
  color: #FFFFFF;
}

button.yeuls-button.outlined.Mui-disabled {
  border: 2px solid #AFB4C1;
  background: #FFFFFF;
  color: #AFB4C1;
}

.rotate {
  button.yeuls-button {
    transform: rotateY(180deg);
  }
}